<template>
  <div class="terms">
    <h1 class="title">关于我们</h1>
    <p class="titleInfo">About Us</p>
    <p class="s1">
      Vancheen是B2C跨境移动电子交易市场的应用。我们的使命是为全球消费者提供高品质、低成本的多元化产品，同时为来自不同国家的买家提供适合他们的多语种服务，给他们便捷的跨境线上购物体验。
    </p>
    <p class="s1">
      Vancheen is the application of B2C cross-border mobile e-trading
      marketplace. Our mission is to provide high-quality, low-cost diversified
      products to the global consumers, meanwhile Vancheen provides multilingual
      services which suitable for the buyers from different countries and gives
      them the convenice cross-border online shopping experience.
    </p>
  </div>
</template>
<script>
export default {
  name: "AboutUs",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.terms {
  width: 90%;
  margin: 0 auto;
  * {
    font-size: 11px;
  }
  .title {
    width: 100%;
    text-align: center;
    font-size: 13px;
    margin-top: 5%;
  }
  .titleInfo {
    width: 100%;
    text-align: center;
    margin: 10px 0;
  }
  .info {
    width: 100%;
    text-align: center;
    margin: 25px 0;
  }
  .s1 {
    text-align: left;
    margin: 25px 0;
  }
  .sTitle {
    text-align: left;
    margin: 25px 0;
  }
}
.agreeBox {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: white;
  padding: 3% 0;
  text-align: center;
  border-top: 1px solid #e5e5e5;
  .agree {
    width: 80%;
    margin: auto;
    border-radius: 3rem;
    padding: 0.8rem 0;
    text-align: center;
    background: linear-gradient(left, #71d283, #01aaa3);
    color: #ffffff;
    font-size: 1rem;
    line-height: 1;
  }
}
</style>
